<template>
  <div class="documents">
    <Header />
    <Policy />
    <TitleRefugeesMenuTemplate
      style="background: #2c82c9"
     :title="title.titlePage" 
     :subtitle="title.subtitlePage"
     :img="title.img"/>
    <InformationsTemplate :card="caritas"/>
    <InformationsTemplate :card="ctps" />
    <InformationsTemplate :card="refugio" />
    <InformationsTemplate :card="registro" />
    <InformationsTemplate :card="residencia" />
    <InformationsTemplate :card="visto" />
    <p><router-link :to="{ name: 'refugees-menu-PT' }">{{$t('document.link')}}</router-link></p>
    <Contact />
    <Feedback />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import TitleRefugeesMenuTemplate from "@/components/templates/TitleRefugeesMenuTemplate";
import InformationsTemplate from "@/components/templates/InformationsTemplate";
import Contact from "@/components/Contact";
import Feedback from "@/components/Feedback";
import Footer from "@/components/Footer";
import Policy from "@/components/Policy";

export default {
  data() {
    return {
       title: {
        titlePage: this.$t('document.titlePage'),
        subtitlePage: this.$t('document.subtitlePage'),
        img: "documents.png"
      },
      refugio: {
        title: this.$t('document.refugioTitle'),  
        text: this.$t('document.refugioText'),  
        link: "https://www.gov.br/pf/pt-br",
        linkName: this.$t("document.refugioLinkName"),
      },
      ctps: {
        title: this.$t("document.ctpsTitle"),
        text:this.$t("document.ctpsText"),
        link: " https://www.vagas.com.br/profissoes/como-tirar-carteira-de-trabalho/",
        linkName: this.$t("document.linkName")
      },
      registro: {
        title: this.$t("document.registroTitle"),
        text: this.$t("document.registroText"),
        link: "https://www.s2vistos.com.br/servicos/rne/",
        linkName: this.$t("document.linkName")
      },
      residencia: {
        title: this.$t("document.residenciaTitle"),
        text: this.$t("document.residenciaText"),
        link: "https://www.gov.br/pt-br/servicos/obter-autorizacao-de-residencia-e-carteira-de-registro-migratorio",
        linkName: this.$t("document.linkName")
      },
      visto: {
        title: this.$t("document.vistoTitle"),
        text: this.$t("document.vistoText"),
        link: "https://www.gov.br/pf/pt-br/assuntos/imigracao/autorizacao-residencia/formularios/capa",
        linkName: this.$t("document.linkName")
      },
       caritas: {
        title: this.$t("legal.caritasTitle"),
        text: this.$t("legal.caritasText"),
        link: "http://www.caritas-rj.org.br/",
        linkName: this.$t("legal.caritasLinkName"),
      },
    };
  },
  components: {
    Header,
    TitleRefugeesMenuTemplate,
    InformationsTemplate,
    Contact,
    Feedback,
    Footer,
    Policy
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.documents {
  width: 100%;
  height: 100%;
}
.style {
  background: #000;
}
p {
  margin: 0 0 50px 120px;
}
</style>